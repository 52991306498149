
.container404 {
    padding: 70px;
    text-align: center;
    transition: all 0.2s;
}

.title404 {
    font-size: 70px;
    font-weight: bold;
}

.msg404 {
    font-size: 20px;
    margin: 20px 0 30px 0;
}


@media screen and (max-width: 450px) {
    .container404 {
        padding: 20px;
    }
}