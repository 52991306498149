.mobileMenuWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  --mobileMenuHeaderHeight: 50px;
}
.mobileMenuOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.582);
}
.mobileMenu {
  width: 90%;
  min-width: 200px;
  max-width: 350px;
  /* height: 90%; */
  background: white;
  border-radius: 7px;
  box-shadow: 0 1px 8px 0px rgb(60 64 67 / 10%);
  transition: 0.2s all;
  z-index: 1;
  overflow: hidden;
}
.mobileMenuHeader {
  height: var(--mobileMenuHeaderHeight);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  border-bottom: 1px solid #e0e0e0;
}
.mobileMenuHeaderLogo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: initial;
  flex-grow: 1;
}
.mobileMenuHeaderLogo img {
  height: 30px;
}
.mobileMenuHeaderLogo .name {
  font-size: 22px;
  font-weight: bold;
  /* margin-left: 10px; */
}

.mobileMenuHeader .mobileMenuHeaderClose {
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: #5a5a5a;
  padding: 5px;
}
.mobileMenuHeader .mobileMenuHeaderClose:hover {
  background-color: #eeeeee;
}

.mobileMenuBody {
  height: calc(100% - var(--mobileMenuHeaderHeight));
  padding: 10px 0;
  overflow: hidden;
  overflow-y: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.mobileMenuItem {
  width: 100%;
  padding: 15px 20px;
  cursor: pointer;
  color: initial !important;
  font-weight: 500;
}
.mobileMenuItem:hover {
  background-color: var(--colorHover);
}
.mobileMenuItem.active {
  background-color: var(--colorPrimary);
  color: var(--white) !important;
}
