@import url(../css/colors.css);

body {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  --app-font: "Roboto", sans-serif;
}
button,
select,
textarea,
input {
  outline: none;
  border: none;
}

a:link,
a:visited,
.linkBold {
  text-decoration: none;
  color: var(--colorPrimary);
}
.linkBold {
  font-weight: bold;
}

.buttonPrimary,
.buttonPrimaryAlpha {
  min-width: 100px;
  text-align: center;
  padding: 8px 15px;
  font-family: var(--app-font);
  font-size: 16px;
  font-weight: 500;
  color: var(--colorPrimary);
  border-radius: 7px;
  cursor: pointer;
  background-color: var(--colorPrimary);
  color: var(--white) !important;
}
.buttonPrimaryAlpha {
  background-color: var(--colorPrimaryAlpha20);
  color: var(--colorBodyText);
}
.buttonPrimary:hover {
  background-color: var(--colorPrimaryDark);
}
.buttonPrimary:active {
  background-color: var(--colorPrimaryDark2);
}
.buttonPrimaryAlpha:hover {
  background-color: var(--colorPrimaryAlpha30);
}
.buttonPrimaryAlpha:active {
  background-color: var(--colorPrimaryAlpha50);
}
.buttonPrimary.disabled,
.buttonPrimaryAlpha.disabled {
  pointer-events: none;
  background-color: var(--colorBGButtonDisabled);
  color: var(--colorTextButtonDisabled);
  cursor: default;
}
