html {
  --colorPrimary: #2d8559;
  --colorPrimaryDark: #007444;
  --colorPrimaryDark2: #00643a;
  --colorSecondary: #d4af37;
  --colorSecondaryDark: #9e8120;
  --colorSecondaryAlpha80: rgba(212, 175, 55, 0.8);
  --colorPrimaryAlpha50: rgba(0, 139, 90, 0.5);
  --colorPrimaryAlpha30: rgba(0, 139, 90, 0.3);
  --colorPrimaryAlpha20: rgba(0, 139, 90, 0.2);
  --colorPrimaryAlpha10: rgba(0, 139, 90, 0.1);

  --white: #ffffff;
  --white2: #e0e0e0;
  --white3: #c0c0c0;
  --white4: #a0a0a0;

  --black: #000000;
  --black2: #333333;
  --black3: #777777;
  --black4: #aaaaaa;

  --colorText: #000000;
  --colorText2: #333333;
  --colorText3: #777777;
  --colorText4: #aaaaaa;

  --colorBGButtonDisabled: rgba(171, 171, 171, 0.3);
  --colorTextButtonDisabled: #aaaaaa;

  --colorHover: rgba(133, 133, 133, 0.2);
}
